import React from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import GatsbyImage from 'gatsby-image';
import './Versiones.css';

export default function Versiones({verFeatur, setVerFeatur, setFeaturesVersion, modelo, anio, versiones, features}) {
 
    const mapVersiones = versiones?.map((obj, i) => {
        return(
            <Col key={i} className={`${obj.name === verFeatur ? "btn-versionactiva" : "btn-version"}`} onClick={() => cambioDetalles(obj)}>
                <div>{obj.name}</div>
            </Col>
        )
    })

    const mapFeatures = features?.map((obj, i) => {
        
        return (
            <Row key={i}>
                <div className="feature-versiones" >
                    <img src={obj.icon} alt="feature" className="iconimg"/>{obj.name}
                </div>
            </Row>
        )
    })

    function cambioDetalles(version){
        console.log(version);
        setFeaturesVersion(version.features);
        setVerFeatur(version.name);
    }

    return(
        <>
            <Container className=" d-lg-block d-none Container-Web-Versiones" >
                <Row style={{height: '100%'}}>
                    <Col xl={8} lg={8} style={{height: '100%'}}>
                        <Container className="p-4" style={{height: '100%'}}>
                            <Col lg={12} md={12} sm={12} className="p-2">
                                <h1 className="texto-titulo-componente"><span style={{color: `orange`}}>VERSIONES</span></h1>
                            </Col>

                            <Container>
                                <Row>
                                    {mapVersiones} 
                                </Row>
                            </Container>

                            <Container className="p-3 row-container-abajo">
                                <Row >
                                    <Col lg={4} md={4} sm={4} style={{display: 'flex', justifyContent: 'center', height: '50vh'}}>
                                        <GatsbyVersionesIzquierda modelo={modelo?.replace(/ /g, '-')} anio={anio} verFeatur={verFeatur}/>
                                    </Col>
                                    <Col lg={8} md={8} sm={8} >
                                        <div style={{color: '#FFF', padding: 10}}>La versión {verFeatur} cuenta con: </div> 
                                        <Row className="row-scroll-versions p-3">
                                            <Col sm={12} className="feature-item-versiones">
                                                {mapFeatures}
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                    </Col>
                    <Col xl={4} lg={4} style={{height: '100%'}}>  
                        <GatsbyVersionDerecha modelo={modelo?.replace(/ /g, '-')} anio={anio}/>
                    </Col>
                </Row> 
            </Container>

            <Row className="CFondoMovil d-md-block d-lg-none">
                <Container style={{paddingBottom: '60px'}}>
                    <Container>
                        <Row className="p-1 m-0 pt-3" xs={2} >
                            {mapVersiones} 
                        </Row>
                    </Container>

                        <Row className="p-3 row-container-abajo">
                            <Col >
                                <div className="p-2" style={{color: '#FFF'}}>La versión {verFeatur} cuenta con: </div> 
                                <Row className="row-scroll-versions p-3">
                                    <Col className="feature-item-versiones">
                                        {mapFeatures}
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                </Container>
            </Row>
        </>
    )

}

function GatsbyVersionDerecha({modelo, anio}){
    return(
        <GatsbyImage
            fluid={{
            aspectRatio: 1 / 1,
            src: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Version_lateral_${modelo}_${anio}.png`,
            srcSet: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Version_lateral_${modelo}_${anio}.png, https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Version_lateral_${modelo}_${anio}.png`,
            sizes: "quality: 80",
            }}
            style={{height: '100%'}}
            imgStyle={{width: '100%', objectFit: 'cover'}}
        />
    )
}

function GatsbyVersionesIzquierda({modelo, anio, verFeatur}){
    let replaceVersion = verFeatur?.replace(/ /g, "-");
    return(
        <GatsbyImage
            fluid={{
                aspectRatio: 1 / 1,
                src: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Version_${replaceVersion}_${modelo}_${anio}.png`,
                srcSet: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Version_${replaceVersion}_${modelo}_${anio}.png, https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Version_${replaceVersion}_${modelo}_${anio}.png`,
                sizes: "quality: 80",
            }}
            style={{height: '100%', width: '100%'}}
            imgStyle={{objectPosition: 'top', objectFit: 'contain'}}
        />
    )
}
