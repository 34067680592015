import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import GatsbyImage from "gatsby-image";
import './Galeria.css'
import ModalGaleria from "../widgets/modalGaleria";

export default function Galeria({modelo, anio, fotos, video}){

    const [ show, setShow] = useState(false);
    const [ datos, setDatos ] = useState({
        nombre: "",
        datos: ""
    })
    const handleClose = () => setShow(false);

    const nomGallery = [
        {nombre: 'foto', tag: "FOTOS", datos: fotos},
        {nombre: 'video', tag: "VIDEO", datos: video}
    ];

    const mappingGallery = nomGallery?.map((obj, i) =>{
        return(
            <div key={i} className="p-3 col-container-imagen">
                <div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                {/* Modificar */}
                    <div className="imagen-galeria">
                        <GIGaleria modelo={modelo?.replace(/ /g, '-')} anio={anio} nombre={obj.nombre}/>
                    </div>
                    { obj.datos && obj.datos.length > 0 ? (
                        <div className="base-azul" onClick={() => {setShow(true); setDatos({...datos, nombre: obj.nombre, datos: obj.datos})}}>
                            <div style={{fontWeight: '700', fontSize: '20px', color: '#FFF'}}>{obj.tag}</div>
                        </div>
                    ) : null
                    }
                </div>
            </div>
        )
    })

    return( 
        <>
            <Container className="d-md-block d-none" style={{background: '#292727', height: '100vh', paddingLeft: 0, paddingRight:0, maxWidth: 1400, paddingTop: 120}}>
                <Container fluid className="espacio">
                    <h1 className="texto-titulo-componente"><span style={{color: `orange`}}>GALERÍA</span> DE FOTOS Y VIDEO.</h1>
                </Container>

                <div  style={{ height: '65vh'}}>
                    <div  className="container-galeria">
                        {mappingGallery}
                    </div> 
                </div>
            </Container>

            <Row className="d-sm-block d-md-none" style={{background: '#292727', height: '100vh', paddingLeft: 0, paddingRight:0, paddingTop: 120}}>
                <Container className="container-galeria-movil">
                    <div className="p-3 col-container-imagen">
                        <div style={{width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                        {/* Modificar */}
                            <div className="imagen-galeria">
                                <GIGaleria modelo={modelo?.replace(/ /g, '-')} anio={anio} nombre="video"/>
                            </div>
                            {
                                fotos && fotos.length > 0 ? (
                                    <div className="base-azul" onClick={() => {setShow(true); setDatos({...datos, nombre: 'foto', datos: fotos})}}>
                                        <div style={{fontWeight: '700', fontSize: '20px', color: '#FFF'}}>FOTOS</div>
                                    </div>
                                ) : null
                            }
                            {
                                video && video.length > 0 ? (
                                    <div className="base-azul" onClick={() => {setShow(true); setDatos({...datos, nombre: 'video', datos: video})}}>
                                        <div style={{fontWeight: '700', fontSize: '20px', color: '#FFF'}}>VIDEO</div>
                                    </div>
                                )  : null
                            }
                        </div>
                    </div>
                </Container> 
            </Row>

            <ModalGaleria show={show} handleClose={handleClose} datos={datos}/>
        </>
    )

}

function GIGaleria({modelo, anio, nombre}){

    return(
        <GatsbyImage
            fluid={{
            aspectRatio: 1 / 1,
            src: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Galeria_${nombre}_${modelo}_${anio}.png`,
            srcSet: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Galeria_${nombre}_${modelo}_${anio}.png, https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Galeria_${nombre}_${modelo}_${anio}.png`,
            sizes: "quality: 80",
            }}

        />
    )

}
