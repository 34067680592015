import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import Image from "../../images/icons/btn_download.png";
import iconDocs from "../../images/icons/btn_doc.png";

export default function InformacionTecnica({ specsUrl , manualUrl , catalogUrl , car, versions, modelo, setShowModalSale }) {

  return (
    <div className="bg-dark mt-4 p-2 mb-4">
      {specsUrl || manualUrl || catalogUrl ? (
        <div className="p-3">
          <h4 className="text-white">
            <img src={iconDocs} width="30px" />
            &nbsp;&nbsp; Descargar más información aquí.
          </h4>
        </div>
      ) : null}

      <Row className="p-4 transition-all-300">
        {specsUrl && modelo !== 'CHEYENE' && modelo !== 'CAMARO' && modelo !== 'CORVETTE' ? (
          <Col lg={4} md={4} sm={12} className="text-center mb-2">
            <a target="_blank" href={specsUrl} rel="noreferrer">
              <div className="descarga bg-light text-dark p-3">
                <center>
                  <div style={{ width: 30, float: "left" }}>
                    <img src={Image} alt="Ficha Técnica" />
                  </div>
                  Descargar ficha técnica {car ? car.year : null}
                </center>
              </div>
            </a>
          </Col>
        ) : null}

        {manualUrl ? (
          <Col lg={4} md={4} sm={12} className="text-center mb-2">
            <a target="_blank" href={manualUrl} rel="noreferrer">
              <div className="descarga bg-light text-dark p-3">
                <center>
                  <div style={{ width: 30, float: "left" }}>
                    <img src={Image} alt="Guía del Propietario" />
                  </div>
                  Descargar guía del propietario
                </center>
              </div>
            </a>
          </Col>
        ) : null}

        {catalogUrl ? (
          <Col lg={4} md={4} sm={12} className="text-center mb-2">
            <a target="_blank" href={catalogUrl} rel="noreferrer">
              <div className="descarga bg-light text-dark p-3">
                <center>
                  <div style={{ width: 30, float: "left" }}>
                    <img src={Image} alt="Catálogo" />
                  </div>
                  Descargar catálogo {car ? car.year : null}
                </center>
              </div>
            </a>
          </Col>
        ) : null}
      </Row>
    </div>
  );
}
