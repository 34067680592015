import React from "react";
import { Container,Col, Row, Button, Placeholder } from "react-bootstrap";
import { FaArrowLeft } from "react-icons/fa";
import GatsbyImage from "gatsby-image";
import Image from "../widgets/basico/image";
import { NumericFormat } from "react-number-format";
import { Link } from "gatsby";
import "./Principal.css";

export default function Principal({background, colores, modelo, anio, anios, menualPrincipal, onColorChange, colorSelect, setShowModal, onPageReloadNavigate, parameters}){
    //console.log(anio)

    const coloresMap = colores?.map((obj, i) => {
        return(
            <div key={i} className="cuadrado-color" style={{backgroundColor: `${obj.hexadecimal}`}} onClick={() => onColorChange(obj.name)}></div>
        )
    });

    const aniosMapping = anios?.map((obj, i) => {
        //console.log("Tengo anios: ", obj);
        return(
            <div key={i} className={anio === obj.year ? "container-anio": "container-anio-inactivo"} onClick={() => onPageReloadNavigate(obj.slug)}>
                {obj.year}    
            </div>
        )
    });

    return(
        <>
            <Container id="container-principal">
                <div className="Container-Web d-md-block d-none">
                    <Container style={{ display: "flex", maxWidth: 1400}} >
                        <Col lg={12} md={12} sm={12}>
                            <div style={{ height: "50vh", width: "100%" }}>
                                <Image
                                    fileName={`Fondo_${modelo?.replace(/ /g, '-')}_${anio}_${background}.jpg`}
                                    alt="galeria video silverado"
                                    imgStyles={{ width: "100%", height: "100vh" }}
                                    style={{ width: "100%", height: "100vh" }}
                                />
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={12} style={{ position: "absolute", top: 0, paddingRight: 24}}>
                            <div id="carroColores" style={{ height: "50vh", width: "100%"}} >
                                <ImgBackgroundCarro background={background} modelo={modelo?.replace(/ /g, '-')} anio={anio} colorSelect={colorSelect} />
                            </div>
                        </Col>
                    </Container>

                    <Row className="row-container">
                        <Col lg={7} md={8} style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
                            <Container style={{paddingTop: 120}}>
                                <Link to={parameters ?  "/chevrolet/catalogo/nuevos" + parameters  :   "/chevrolet/catalogo/nuevos"}>
                                    <div style={{backgroundColor: '#c27700', width: '25%', color: 'white', borderRadius: 5, fontSize: 13, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                        <FaArrowLeft/>Cambiar de Unidad
                                    </div>
                                </Link>
                            </Container>
                            <Container className="container-titulo">
                                <div style={{margin: 5}}>
                                    {aniosMapping} 
                                </div>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p className="texto-grande">¡VIVE GRANDES AVENTURAS!</p> <span className="marco-modelo">{modelo}</span>
                                </div>
                                <div>
                                    <p className="texto-enganche">25% Enganche / 60 meses desde:</p>
                                </div>
                                <div className="divPrecioPrincipal">
                                    {menualPrincipal ? (
                                        <>
                                            <span className="spanDivPrecioCotizaExtra">$ </span>
                                            <h1 className="texto-precio"><NumericFormat displayType="text" value={menualPrincipal} allowLeadingZeros thousandSeparator="," /></h1>
                                            <span className="spanDivPrecioCotizaExtra">MXN</span>
                                        </>
                                    ) : (
                                        <Placeholder xs={6} bg="dark" />
                                    )}
                                </div>
                                <div style={{margin: 5}}>
                                    <p className="texto-informacion">
                                        *Planes sujetos a cambio sin previo aviso, crédito sujeto a aprobación por financiera,
                                        no incluye Placas, planes SIN anualidades. Aplican restriciones.
                                    </p>
                                </div>
                                <div style={{margin: 5}}>
                                    <Button className="btn_cotiza" onClick={() => setShowModal(true)}>Cotiza en Linea</Button>
                                </div>
                            </Container>
                        </Col>
                        <Col lg={5} md={4} className="col-itemsAbajo">
                            <Container style={{height: '20%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}>
                                <Row style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                    {coloresMap}
                                </Row>
                                <Row>
                                    <p className="texto-grande"> {colorSelect}</p>
                                </Row>
                            </Container>
                        </Col>
                    </Row>

                </div>

                <div className="d-sm-block d-md-none">
                    <div style={{position: 'relative'}}>
                        <div style={{position: 'absolute', height: "90vh", width: '100vw' }}>
                            <ImgBackMovil background={background} modelo={modelo} anio={anio} />
                        </div>
                        <div style={{ height: "90vh", width: '100vw' }}>
                            {<ImageCarroMovil background={background} modelo={modelo} anio={anio} colorSelect={colorSelect}/>}
                        </div>

                        <div className="row-container-movil">
                            <Container style={{height: '65%'}}>
                                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    <Row style={{width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                                        {coloresMap}
                                    </Row>
                                    <Row>
                                        <p className="texto-grande"> {colorSelect}</p>
                                    </Row>
                                </div>
                                <div className="p-2" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <p className="texto-grande">¡VIVE GRANDES AVENTURAS!</p> <span className="marco-modelo">{modelo}</span>
                                </div>
                                <div className="p-2" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    <p className="texto-enganche">25% Enganche / 60 meses desde:</p>
                                </div>
                                <div className="divPrecioPrincipal" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                    {menualPrincipal ? (
                                        <>
                                            <span className="spanDivPrecioCotizaExtra">$ </span>
                                            <h1 className="texto-precio"><NumericFormat displayType="text" value={menualPrincipal} allowLeadingZeros thousandSeparator="," /></h1> 
                                            <span className="spanDivPrecioCotizaExtra">MXN</span>
                                        </>
                                    ) : (
                                        <Placeholder xs={6} bg="dark" />
                                    )}
                                    <Button className="btn_cotiza" onClick={() => setShowModal(true)}>Cotiza en Linea</Button>
                                </div>
                                <Row  style={{margin: 15, display: 'flex', justifyContent: 'center'}}>
                                    {aniosMapping}
                                </Row>
                                <Row style={{margin: 5}}>
                                    <p className="texto-informacion">
                                        *Planes sujetos a cambio sin previo aviso, crédito sujeto a aprobación por financiera,
                                        no incluye Placas, planes SIN anualidades. Aplican restriciones.
                                    </p>
                                </Row>
                            </Container>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )

}

const ImgBackgroundCarro = ({background, modelo, anio, colorSelect}) => {

    let cSelect = colorSelect?.replace(/ /g, "-");
    
    return (
    <>
        <GatsbyImage
        fluid={{
            aspectRatio: 1 / 1,
            src: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Colores/${background}/${modelo}_${cSelect}_${anio}_${background}.webp`,
            srcSet: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Colores/${background}/${modelo}_${cSelect}_${anio}_${background}.webp, https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Colores/${background}/${modelo}_${cSelect}_${anio}_${background}.webp`,
            sizes: "quality: 80",
        }}
        imgStyles={{ width: "100%", height: "100vh" }}
        style={{ width: "100%", height: "100vh" }}
        className="animation-fade"
        />
    </>
    );
};

const ImageCarroMovil = ({background, modelo, anio, colorSelect}) => {

    let modeloSelect = modelo?.replace(/ /g, "-");
    let cSelect = colorSelect?.replace(/ /g, "-");

    return (
        <>
            <GatsbyImage
            fluid={{
                aspectRatio: 1 / 1,
                src: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modeloSelect}_${anio}/Movil/${background}/${modeloSelect}_${anio}_${cSelect}_movil.webp`,
                srcSet: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modeloSelect}_${anio}/Movil/${background}/${modeloSelect}_${anio}_${cSelect}_movil.webp, https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modeloSelect}_${anio}/Movil/${background}/${modeloSelect}_${anio}_${cSelect}_movil.webp`,
                sizes: "quality: 80",
            }}
            imgStyles={{ width: "100vw", height: "100vh" }}
            style={{ width: "100vw", height: "100vh" }}
            className="animation-fade"
            />
        </>
    );
}

const ImgBackMovil = ({background, modelo, anio}) => {

    let modeloSelect = modelo?.replace(/ /g, "-");

    return (
        <>
            <GatsbyImage
            fluid={{
                aspectRatio: 1 / 1,
                src: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modeloSelect}_${anio}/Movil/${background}/${modeloSelect}_${anio}_movil_${background}.jpg`,
                srcSet: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modeloSelect}_${anio}/Movil/${background}/${modeloSelect}_${anio}_movil_${background}.jpg, https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modeloSelect}_${anio}/Movil/${background}/${modeloSelect}_${anio}_movil_${background}.jpg`,
                sizes: "quality: 80",
            }}
            imgStyles={{ width: "100%", height: "100vh" }}
            style={{ width: "100%", height: "100vh" }}
            />
        </>
    )
    //"https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${stringCarro}_${carroYear}/Movil/${val_back || bg1}/${imageBackMovil}"

}

