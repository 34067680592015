import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import whatsApp from "../../images/icons/barraNav/whatsapp_.png";
import whatsAppA from "../../images/icons/barraNav/whatsapp_h.png";

export default function BotonesNav({barraNavegacion, navActivo}) {

    const object = [
        {nombre: "Principal", tagCss: "principal"},
        {nombre: "Cotiza en línea", tagCss: "cotiza"},
        /*{nombre: "Características", tagCss: "caracteristicas"},*/
        {nombre: "Galería", tagCss: "galeria"},
        {nombre: "Versiones", tagCss: "versiones"}
    ];

    return(
        <Container className="container-btn-navegacion d-md-block d-none">
            <div className="col-naventante">
                {object.map((obj, i) => {
                    return(
                        <div key={i} className={navActivo === obj.nombre ? `btn-navegar-${obj.tagCss}-activo btn-navegar-segundo` : `btn-navegar-${obj.tagCss} btn-navegar-segundo`} onClick={() => barraNavegacion(obj.nombre)}>
                            <div className="texto-navegante">{obj.nombre}</div>
                        </div>
                    )
                })}
                <a href="https://wa.me/+5218116001600" data-metakey="mobile-btn-wup" target="_blank" rel="noreferrer">
                    <img
                        src={whatsApp}
                        alt="checkbox"
                        className="btn-navegar-whatsApp"
                        loading="lazy"
                        onMouseOver={(e) => (e.currentTarget.src = whatsAppA)}
                        onMouseOut={(e) => (e.currentTarget.src = whatsApp)}
                    />
                </a>
            </div>
        </Container>
    )

}