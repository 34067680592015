import React, { useState } from "react";
import { Col, Row, Container, Placeholder } from "react-bootstrap";
import GatsbyImage from "gatsby-image";
import RangeSlider from 'react-bootstrap-range-slider';
import { NumericFormat } from "react-number-format";
import "./Cotiza.css";
import imgCalcular from "../../images/icons/cotizar_white.png";

export default function Cotiza({valorPorcentaje, cargaPMensual, versionSelect, onVersionSelect, mesValor, onMensualidad, imagenColor, versiones, modelo, anio, precio, onEngancheChange, onPagoMensualidad, pagoMensual, enganche}) {

    const [ value, setValue ] = useState(25);
    const mesitos = [12, 24, 36, 48, 60]

    const mappingVersions = versiones?.map((obj, i) => { 
        return(
            <Col key={i} 
                style={{margin: 5}}
                onClick={() => onVersionSelect(obj.name, obj.price, obj.id)}
                className={obj.name === versionSelect.version ? "opcionesModeloActivo" : "opcionesCotiza"} >
                    {obj.name}
            </Col>
        )
    });

    const mappingMonth = mesitos.map((obj, i) => {
        return(
            <Col key={i} className={obj === mesValor ? "botonMesesActivo" : "botonMeses"} style={{margin: 4}} onClick={() => onMensualidad(obj)}>
                {obj}
            </Col>
        )
    });

    return(
        <>
            <div className="d-md-block d-none">
                <Container style={{maxWidth: 1400, paddingTop: 120}}>
                    <Row style={{backgroundColor: 'rgb(41, 39, 39)'}}>
                        <Col xl={8} lg={8} style={{height: '90vh', paddingTop: 10, paddingRight: 20, paddingLeft: 20, overflowX: 'scroll'}}>
                            <Row>
                                <h2 className="texto-titulo-componente">
                                    <span style={{ color: `orange` }}>COTIZA</span> EN LINEA.
                                </h2>
                            </Row>
                            <Row className="row-container-opciones">
                                <Col xl={6} lg={6} md={6} style={{justifyContent: 'flex-end', display: 'flex', flexDirection: 'column'}}>
                                    <Row>
                                        <CarritoCotiza imagenColor={imagenColor}/>
                                        <div className="cont-texto-modelo2">
                                            Chevrolet {modelo} {anio}
                                        </div>
                                    </Row>
                                    <Row>
                                        <div className="segmentobarra">
                                            <div className="texto-azul">Modelo</div>
                                            <div className="barra"></div>
                                        </div>
                                        {
                                            mappingVersions
                                        }
                                    </Row>
                                    <Row>
                                        <div className="texto-blanco">Precio</div>
                                        <Col>
                                            <div className="divPrecioCotiza">
                                            <span className="spanDivPrecioCotizaExtra">$ </span>
                                                    <h1 id="texto_Lato_subtittle"><NumericFormat displayType="text" value={versionSelect.precio} allowLeadingZeros thousandSeparator="," /></h1>
                                                    <span className="spanDivPrecioCotizaExtra">MXN</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl={6} lg={6} md={6} style={{ padding: 0, justifyContent: 'flex-end', display: 'flex', flexDirection: 'column'}}>
                                    <Container>
                                        <Col>
                                            <div className="segmentobarra marginMovil">
                                                <div className="texto-azul">Plazo</div>
                                                <div className="barra"></div>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Row> { mappingMonth } </Row>
                                        </Col>
                                        <Col>
                                            <div className="textPlazo">{mesValor} meses</div>
                                        </Col>
                                    </Container>
                                    <Container>
                                         <Row>
                                            <div className="segmentobarra marginMovil">
                                                <div className="texto-azul">Enganche</div>
                                                <div className="barra"></div>
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="texto-valor-enganche">
                                                <div className="textPlazo textEnganche">
                                                    {valorPorcentaje}%
                                                </div>
                                            </div>
                                            <RangeSlider
                                                step={1}
                                                min={10}
                                                max={60}
                                                value={valorPorcentaje}
                                                onChange={changeEvent => {onEngancheChange(changeEvent.target.value)}}
                                            />
                                        </Row>
                                        <Col>
                                            <div className="divPrecioCotiza">
                                                <span className="spanDivPrecioCotizaExtra">$ </span>
                                                <h1 id="texto_Lato_subtittle"><NumericFormat displayType="text" value={enganche} allowLeadingZeros thousandSeparator="," /></h1>
                                                <span className="spanDivPrecioCotizaExtra">MXN</span>
                                            </div>
                                        </Col>
                                    </Container>
                                </Col>
                            </Row>
                            <Row >
                                <div className="texto-blanco">Pago mensual de:</div>
                                <Col xl={10} lg={10} md={10}>
                                    <div className="colPagoMensual">
                                        {cargaPMensual ? (
                                            <>
                                                <span className="spanDivPrecioCotizaExtra">$ </span>
                                                    <h1 id="texto_Lato_subtittle"><NumericFormat displayType="text" value={pagoMensual} allowLeadingZeros thousandSeparator="," /></h1>
                                                <span className="spanDivPrecioCotizaExtra">MXN</span>
                                            </>
                                        ) : (
                                            <Placeholder xs={6} bg="dark" />
                                        )}
                                    </div>
                                </Col>
                                <Col xl={2} lg={2} md={2}>
                                    <div className="divBotonCalc" style={{background: `orange`, height: "8vh", width: "100%"}} onClick={() => onPagoMensualidad()}>
                                        <img src={imgCalcular} style={{height: '60%', width: '40%'}}/>
                                        <div id="texto-botoncalcular-cotiza">calcular</div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <p className="cotizar-restricciones">
                                    *Planes sujetos a cambios sin previo aviso, crédito
                                    sujeto a aprobación por financiera. No incluye Placas,
                                    Planes SIN anualidades. Aplican restricciones.
                                </p>
                                {/*<div className="espacio-cotiza-landscape"></div>*/}
                            </Row>
                        </Col>
                        <Col xl={4} lg={4} className="d-lg-block d-md-none" style={{height: '99vh', paddingLeft: 0, paddingRight: 0}}>
                            <ImgBackgroundCotiza modelo={modelo?.replace(/ /g, '-')} anio={anio}/>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div className="d-sm-block d-md-none">
                <Row style={{backgroundColor: 'rgb(41, 39, 39)', height: '100vh', overflowY: 'scroll', paddingTop: 120}}>
                    <Container>
                        <Col md={12} xs={12} >
                            <CarritoCotiza imagenColor={imagenColor}/>
                            <div className="cont-texto-modelo2">
                                Chevrolet {modelo} {anio}
                            </div>
                        </Col>
                        <Col md={12} xs={12}>
                            <div className="segmentobarra">
                                <div className="texto-azul">Modelo</div>
                                <div className="barra"></div>
                            </div>
                            <Container><Row> { mappingVersions } </Row></Container>
                        </Col>
                        <Col>
                            <div className="texto-blanco">Precio</div>
                            <Col>
                                <div className="divPrecioCotiza">
                                <span className="spanDivPrecioCotizaExtra">$ </span>
                                    <h1 id="texto_Lato_subtittle"><NumericFormat displayType="text" value={versionSelect.precio} allowLeadingZeros thousandSeparator="," /></h1>
                                    <span className="spanDivPrecioCotizaExtra">MXN</span>
                                </div>
                            </Col>
                        </Col>
                        <Col>
                            <div className="segmentobarra">
                                <div className="texto-azul">Plazo</div>
                                <div className="barra"></div>
                            </div>
                            <Container> <Row>{ mappingMonth } </Row> </Container>
                            <div style={{display: 'flex', justifyContent: 'center'}}>
                                <div className="texto-blanco">{mesValor} meses</div>
                            </div>
                        </Col>
                        <Col>
                            <div className="segmentobarra">
                                <div className="texto-azul">Enganche</div>
                                <div className="barra"></div>
                            </div>
                        </Col>
                        <Col>
                            <div className="texto-valor-enganche">
                                <div className="texto-blanco ">
                                    {valorPorcentaje}%
                                </div>
                            </div>
                            <RangeSlider
                                step={1}
                                min={10}
                                max={60}
                                value={valorPorcentaje}
                                onChange={changeEvent => {onEngancheChange(changeEvent.target.value)}}
                            />
                        </Col>
                        <Col>
                            <div className="divPrecioCotiza">
                                <span className="spanDivPrecioCotizaExtra">$ </span>
                                <h1 id="texto_Lato_subtittle"><NumericFormat displayType="text" value={enganche} allowLeadingZeros thousandSeparator="," /></h1>
                                <span className="spanDivPrecioCotizaExtra">MXN</span>
                            </div>
                        </Col>
                        <Col style={{height: '13%'}}>
                            <div className="texto-blanco pb-3 pt-2">Pago mensual de:</div>
                            <Row>
                                <Col md={9} xs={9}>
                                    <div className="colPagoMensual">
                                        {cargaPMensual ? (
                                            <>
                                                <span className="spanDivPrecioCotizaExtra">$ </span>
                                                    <h1 id="texto_Lato_subtittle"><NumericFormat displayType="text" value={pagoMensual} allowLeadingZeros thousandSeparator="," /></h1>
                                                <span className="spanDivPrecioCotizaExtra">MXN</span>
                                            </>
                                        ) : (
                                            <Placeholder xs={6} bg="dark" />
                                        )}
                                    </div>
                                </Col>
                                <Col md={3} xs={3}>
                                    <div className="divBotonCalc" style={{background: `orange`, height: "8vh", width: "100%"}} onClick={() => onPagoMensualidad()}>
                                        <img src={imgCalcular} style={{height: '65%', width: '50%'}}/>
                                        <div id="texto-botoncalcular-cotiza">calcular</div>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <p className="cotizar-restricciones">
                                *Planes sujetos a cambios sin previo aviso, crédito
                                sujeto a aprobación por financiera. No incluye Placas,
                                Planes SIN anualidades. Aplican restricciones.
                            </p>
                            <div className="espacio-cotiza-landscape"></div>
                        </Col>
                    </Container>
                </Row>
            </div>
        </>
    )

}

function CarritoCotiza({imagenColor}){
    return(
        <GatsbyImage
            fluid={{
            aspectRatio: 1 / 1,
            src: `${imagenColor}`,
            srcSet: `${imagenColor}, ${imagenColor}`,
            sizes: "quality: 80",
            }}
            style={{ height: '25vh', width: "100%" }}
            imgStyle={{  objectFit: 'contain', height: '25vh' }}
        />
    )
}

function ImgBackgroundCotiza ({modelo, anio}){

    return(
        <GatsbyImage
          fluid={{
            aspectRatio: 1 / 1,
            src: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/CotizaLinea_${modelo}_${anio}.png`,
            srcSet: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/CotizaLinea_${modelo}_${anio}.png, https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/CotizaLinea_${modelo}_${anio}.png`,
            sizes: "quality: 80",
          }}
          imgStyles={{ width: "100%", height: "90vh" }}
          style={{ width: "100%", height: "90vh" }}
        />
    )

}
