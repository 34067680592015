import React from "react";
import { Modal, Carousel } from "react-bootstrap";

export default function ModalVideoInteractivo ({show, handleClose, videoIntera}) {

    return(
        <>
          <Modal size="xl" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <Carousel controls={false} indicators={false}>
                        <iframe 
                            src={videoIntera}
                            frameBorder="0" 
                            scrolling="no"
                            allow="autoplay; fullscreen" 
                            allowFullScreen 
                            style={{
                                minHeight: "540px",
                                height: "100%",
                                width: "100%"
                            }}>
                        </iframe>  
                    </Carousel>
                </Modal.Body>
            </Modal>
        </>
    )

}