import React, { useState } from "react";
import { Modal, Row, Col, Container } from "react-bootstrap";
import GatsbyImage  from "gatsby-image";
import "./Caracteristicas.css";
import ModalVideoInteractivo from "../widgets/modalVideoInteractivo";

export default function Caracteristicas({modelo, anio, videoIntera}){

    const [ show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const urlInteractivo = "";

    const pestanasObj = ["Interior", "Exterior", "Desempeño", "Seguridad"];
    const caracteristicasInterior = ['Asientos delanteros tipo banca con descansabrazos plegable con compartimento', 'Control crucero automático electrónico', 
      'Controles eléctricos en ventanas de conductor con función express de un solo toque (arriba/abajo)', 'Cristales con absorción de rayos UV', 'Espejo retrovisor interior con ajuste manual',
      'Seguros eléctricos en todas las puertas', 'Sistema de infoentretenimiento Chevrolet® con pantalla táctil de 7” de alta resolución']
    const caracteristicasExterior = [ 'Caja de carga con tecnología Durabed® y 12 arillos fijos para atar incluidos', 'Caja de carga de acero de alta resistencia',
      '2 ganchos de arrastre delanteros color negro', 'Rines de acero 17"', 'Spray-On® Bedliner, protector anticorrosión adherido a la caja que evita el deslizamiento y movimiento de objetos en la caja de carga', ]
    const caracteristicasSeguridad = [
      'Cámara de visión trasera con despliegue en la pantalla del radio', 'OnStar® 4G LTE (Wi-Fi®) servicios de asistencia personalizada incluyendo un hotspot de Wi-Fi® integrado al vehículo',
      'Sistema de monitoreo de presión de llantas', 'Sistema de diagnóstico de frenos', 'Teen Driver']
    const caracteristicasDesempeno= [
      'Motor 5.3L V8', 'Potencia: 355 HP @ 5,600 rpm', 'Torque: 383 lb-pie @ 4,100 rpm', 'Capacidad de arrastre: 3629 kg', 'Capacidad de carga: 1025 kg']
  
    const [ pestana, setPestana ] = useState(caracteristicasInterior);
    const [ pestanaActiva, setPestanaActiva ] = useState("Interior");
    const [ imagenCaract, setImagenCaract ] = useState("interior");

    // Funciones y consan

    function selectPestana (e){
      setPestanaActiva(e);

      let minusculas = e.toLowerCase();
      let standar = minusculas.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

      setImagenCaract(standar.toLowerCase());
      if(e === "Interior"){setPestana(caracteristicasInterior);}
      else if(e === "Exterior"){setPestana(caracteristicasExterior);}
      else if(e === "Seguridad"){setPestana(caracteristicasSeguridad);}
      else if(e === "Desempeño"){setPestana(caracteristicasDesempeno);}
    }

    const mapPestanas = pestanasObj?.map((obj, i) => {
      return(
        <div key={i} style={{display: "flex", justifyContent: 'space-between', width: '25%'}}>
          <div className="pestanasCaracteristicas">
            <div onClick={() => selectPestana(obj)} className={pestanaActiva === obj ? "opcionCaracteristicaActive" : "opcionCaracteristica"} style={{color: 'white'}}>{obj}</div>
          </div>
          {i < 3 ? <div style={{color: 'white'}}>|</div> : null}
        </div>
      )
    })

    const mapPestanasMovil = pestanasObj?.map((obj, i) => {
      return(
        <div key={i} style={{display: "flex", justifyContent: 'space-between', width: '50%', marginTop: 4}}>
          <div className="pestanasCaracteristicas">
            <div onClick={() => selectPestana(obj)} className={pestanaActiva === obj ? "opcionCaracteristicaActive" : "opcionCaracteristica"} style={{color: 'white'}}>{obj}</div>
          </div>
          {i % 2 - 1 ? <div style={{color: 'white', display: 'flex', alignItems: 'center'}}>|</div> : null}
        </div>
      )
    })

    const mapCaracterisitcas = pestana.map((obj, i) => {
      return(
        <p key={i} className="itemCaracteristica">{obj}</p>
      )
    })

    if(videoIntera){
        //let splitInterac = car.interactive_video.replace("");
        let err = videoIntera.split(/[https://walkaround.gruporivero.com/?-]+/);

        if(err[1] === anio){
          urlInteractivo = videoIntera;
        } else {
        }
    }

    return(
      <>
        <div className="d-md-block d-none">
            <Container style={{ backgroundColor: 'rgb(41, 39, 39)', maxWidth: 1400, display: 'flex', alignItems: 'end', justifyContent: 'center', paddingTop: 120}}>
                <Container style={{ width: '70%', height: 40, display: 'flex', justifyContent: 'center', margin: 5, alignItems: 'center'}}>
                    {mapPestanas}
                </Container>
            </Container>
            <Container className="sloganCaracteristicas" style={{maxWidth: 1400}}>
              <h1 className='texto-titulo-componente'>UN <span style={{color: `orange`}}>GRAN ESPACIO </span> PARA TU FAMILIA.</h1>
            </Container>

            <Container style={{ height: '76vh', maxWidth: 1400, position: 'relative'}}>
                <Row style={{height: '75vh', justifyContent: 'flex-start', alignItems: 'flex-end'}}>
                  <BackgroundCaracterisitcas modelo={modelo?.replace(/ /g, '-')} anio={anio} imagenCaract={imagenCaract}/>
                  <Col className="container-col-caracteristicas">
                    <div className="colCaracteristicas">
                      {mapCaracterisitcas}
                    </div>
                    {
                      urlInteractivo ? (
                        <div className="colVideInteractivo" onClick={() => {setShow(true)}}>
                          <div className="video-font" style={{fontWeight: '600', fontSize: '20px', color: 'white'}}>VER VIDEO INTERACTIVO</div>
                        </div>
                      ) : null
                    }
                  </Col>
                </Row>
            </Container>
        </div>

        <div className="d-sm-block d-md-none" style={{paddingTop: 120}}>
          <Row style={{ backgroundColor: 'black', height: 75}}>
            {mapPestanasMovil}
          </Row>
          <Row style={{height: '79vh', position: 'relative'}}>
            <BackgroundCaracterisitcas modelo={modelo?.replace(/ /g, '-')} anio={anio} imagenCaract={imagenCaract}/>
            <Container style={{position: 'absolute', height: '65vh', justifyContent: 'center', alignItems: 'center', display: 'flex', flexDirection: 'column'}}>
                <div className="colCaracteristicas">
                  {mapCaracterisitcas}
                </div>
                {
                  urlInteractivo ? (
                    <div className="colVideInteractivo">
                      <div className="video-font" style={{fontWeight: '600', fontSize: '20px', color: 'white'}}>VER VIDEO INTERACTIVO</div>
                    </div>
                  ) : null
                }
            </Container>
            {/*<Row style={{position: 'relative'}}>
              
            </Row>
            <Row style={{position: 'relative', backgroundColor: 'saddlebrown', top: 0}}>
              <div>ksjfks</div>
            </Row>*/}
          </Row>
        </div>

        <ModalVideoInteractivo show={show} handleClose={handleClose} videoIntera={urlInteractivo}/>
      </>
    )

}

const BackgroundCaracterisitcas = ({modelo, anio, imagenCaract}) => {
    return (
      <>
        <GatsbyImage
            fluid={{
            aspectRatio: 1 / 1,
            src: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Caracteristicas_${imagenCaract}_${modelo}_${anio}.png`,
            srcSet: `https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Caracteristicas_${imagenCaract}_${modelo}_${anio}.png, https://d3s2hob8w3xwk8.cloudfront.net/nueva-landing-autos/${modelo}_${anio}/Caracteristicas_${imagenCaract}_${modelo}_${anio}.png`,
            sizes: "quality: 80",
            }}
            style={{height: '79vh', backgroundPosition: 'bottom'}}
        />
      </>
    );
};
